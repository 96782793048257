import React from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

import Layout from 'components/Layout'
import Seo from 'components/Seo'

const PrivacyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 70%;
  margin: auto;
`

const PrivacyHeader = styled.h1`
  text-align: center;
  margin-bottom: 1em;
`

const PrivacyPreamble = styled.p`
  font-weight: normal;
  margin-bottom: 1em;
  text-align: center;
  font-size: 1.2rem;
`

const PrivacyBody = styled.ol`
  margin: 0 3em 0 3em;
  font-weight: bold;
  font-size: 1.1rem;
`

const PrivacySection = styled.li`
  margin: 1em 0 1em 0;
`

const PrivacySectionTitle = styled.div`
  font-weight: bold;
`

const PrivacySectionText = styled.p`
  font-weight: normal;
  margin-bottom: 1em;
  text-align: justify;
`

const PrivacySectionUnorderedList = styled.ul`
  margin-left: 3em;
`
const PrivacySectionListItem = styled.li``

const Address = styled.div`
  margin-left: 1.5em;
  font-weight: 600;
`

const PrivacyPolicy = () => {
  const { t } = useTranslation('privacyPolicy')
  return (
    <Layout>
      <Seo title={t('title')} description={t('description')} />
      <PrivacyContainer>
        <PrivacyHeader>{t('header')}</PrivacyHeader>
        <PrivacyPreamble>{t('privacyPreamble')}</PrivacyPreamble>

        <PrivacyBody>
          <PrivacySection id={'overview'}>
            <PrivacySectionTitle>{t('overview.title')}</PrivacySectionTitle>
            <PrivacySectionText>{t('overview.one')}</PrivacySectionText>
            <PrivacySectionText>{t('overview.two')}&nbsp;</PrivacySectionText>
            <PrivacySectionText>{t('overview.three')}</PrivacySectionText>
          </PrivacySection>

          <PrivacySection id={'collected-info'}>
            <PrivacySectionTitle>
              {t('infoCollected.title')}
            </PrivacySectionTitle>
            <PrivacySectionText>{t('infoCollected.one')}</PrivacySectionText>

            <PrivacySectionText>
              {t('infoCollected.two.title')}
              <br />
              <PrivacySectionUnorderedList>
                <PrivacySectionListItem>
                  {t('infoCollected.two.name')}
                </PrivacySectionListItem>
                <PrivacySectionListItem>
                  {t('infoCollected.two.jobTitle')}
                </PrivacySectionListItem>
                <PrivacySectionListItem>
                  {t('infoCollected.two.companyName')}
                </PrivacySectionListItem>
                <PrivacySectionListItem>
                  {t('infoCollected.two.addresses')}
                </PrivacySectionListItem>
                <PrivacySectionListItem>
                  {t('infoCollected.two.mobilePhone')}
                </PrivacySectionListItem>
                <PrivacySectionListItem>
                  {t('infoCollected.two.emailAddress')}
                </PrivacySectionListItem>
                <PrivacySectionListItem>
                  {t('infoCollected.two.ipAddress')}
                </PrivacySectionListItem>
                <PrivacySectionListItem>
                  {t('infoCollected.two.paymentDetails')}
                </PrivacySectionListItem>
                <PrivacySectionListItem>
                  {t('infoCollected.two.marketData')}
                </PrivacySectionListItem>
              </PrivacySectionUnorderedList>
            </PrivacySectionText>
          </PrivacySection>

          <PrivacySection id={'info-usage'}>
            <PrivacySectionTitle>
              {t('useAndDisclosure.title')}
            </PrivacySectionTitle>
            <PrivacySectionText>
              {t('useAndDisclosure.one.title')}
              <PrivacySectionUnorderedList>
                <PrivacySectionListItem>
                  {t('useAndDisclosure.one.order')}
                </PrivacySectionListItem>
                <PrivacySectionListItem>
                  {t('useAndDisclosure.one.personalizedService')}
                </PrivacySectionListItem>
                <PrivacySectionListItem>
                  {t('useAndDisclosure.one.measuringInterest')}
                </PrivacySectionListItem>
                <PrivacySectionListItem>
                  {t('useAndDisclosure.one.marketResearch')}
                </PrivacySectionListItem>
                <PrivacySectionListItem>
                  {t('useAndDisclosure.one.competitions')}
                </PrivacySectionListItem>
                <PrivacySectionListItem>
                  {t('useAndDisclosure.one.infoAboutProducts')}
                </PrivacySectionListItem>
                <PrivacySectionListItem>
                  {t('useAndDisclosure.one.resolvingDisputes')}
                </PrivacySectionListItem>
              </PrivacySectionUnorderedList>
            </PrivacySectionText>
            <PrivacySectionText>{t('useAndDisclosure.two')}</PrivacySectionText>
            <PrivacySectionText>
              {t('useAndDisclosure.three')}
            </PrivacySectionText>
          </PrivacySection>

          <PrivacySection id={'info-usage-japan'}>
            <PrivacySectionTitle>
              {t('useAndDisclosureJapan.title')}
            </PrivacySectionTitle>
            <PrivacySectionText>
              {t('useAndDisclosureJapan.one')}
            </PrivacySectionText>
          </PrivacySection>

          <PrivacySection id={'cookies-tracking'}>
            <PrivacySectionTitle>{t('useOfCookies.title')}</PrivacySectionTitle>
            <PrivacySectionText>{t('useOfCookies.one')}</PrivacySectionText>
            <PrivacySectionText>{t('useOfCookies.two')}</PrivacySectionText>
            <PrivacySectionText>{t('useOfCookies.three')}</PrivacySectionText>
            <PrivacySectionText>{t('useOfCookies.four')}</PrivacySectionText>
          </PrivacySection>

          <PrivacySection id={'security'}>
            <PrivacySectionTitle>
              {t('securityPolicy.title')}
            </PrivacySectionTitle>
            <PrivacySectionText>{t('securityPolicy.one')}</PrivacySectionText>
          </PrivacySection>

          <PrivacySection id={'data-access'}>
            <PrivacySectionTitle>{t('dataAccess.title')}</PrivacySectionTitle>
            <PrivacySectionText>
              {t('dataAccess.one')}
              <Address>
                {t('dataAccess.chiefPrivacyOfficer')}
                <br />
                Virtual Immersive Educational Worlds, Inc.
                <br />
                3317 N. Cottonwood Ln.
                <br />
                Provo, Utah 84604
              </Address>
            </PrivacySectionText>
            <PrivacySectionText>{t('dataAccess.two')}</PrivacySectionText>
            <PrivacySectionText>{t('dataAccess.three')}</PrivacySectionText>
            <PrivacySectionText>{t('dataAccess.four')}</PrivacySectionText>
            <PrivacySectionText>{t('dataAccess.five')}</PrivacySectionText>
            <PrivacySectionText>
              {t('dataAccess.six.one')}{' '}
              <a href="mailto:chiefprivacyofficer@fluentworlds.com">
                chiefprivacyofficer@fluentworlds.com
              </a>
              . {t('dataAccess.six.two')}
            </PrivacySectionText>
          </PrivacySection>

          <PrivacySection id={'children'}>
            <PrivacySectionTitle>{t('children.title')}</PrivacySectionTitle>
            <PrivacySectionText>{t('children.one')}</PrivacySectionText>
          </PrivacySection>

          <PrivacySection id={'changes'}>
            <PrivacySectionTitle>{t('changes.title')}</PrivacySectionTitle>
            <PrivacySectionText>{t('changes.one')}</PrivacySectionText>
            <PrivacySectionText>{t('lastReviewed')}</PrivacySectionText>
            <PrivacySectionText>{t('lastUpdated')}</PrivacySectionText>
          </PrivacySection>
        </PrivacyBody>
      </PrivacyContainer>
    </Layout>
  )
}

export default PrivacyPolicy
